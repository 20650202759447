import React from 'react'
import './portfolio.css';
import P1 from '../../assets/p1.jpg';
import P2 from '../../assets/p2.jpg';
import P3 from '../../assets/p3.jpeg';
import P4 from '../../assets/p4.jpg';
import P5 from '../../assets/p5.jpg';


const Portfolio = () => {
    return (
        <section className="portafolio section" id="portfolio">
            <h2 className="section__title">Portafolio</h2>
            <span className='section__subtitle'>Mis proyectos</span>
            <div id="card__area">
                <div className="wrapper">
                    <div className="box__area">
                        <div className="box">
                            <img alt="" src={P1} />
                            <div className="overlay">
                                <div className='technologies__container'>
                                    <p className='project__technologies'><i className='bx bxl-html5'></i><span>HTML</span></p>
                                    <p className='project__technologies'><i className='bx bxl-css3' ></i><span>CSS</span></p>
                                    <p className='project__technologies'><i className='bx bxl-php' ></i></p>
                                    <p className='project__technologies'><i className='bx bxs-data'></i><span>MySQL</span></p>
                                    <p className='project__technologies'><i className='bx bxs-file-js'></i><span>JS</span></p>

                                </div>

                                <h3>HardWork</h3>
                                <p>¿Quieres adquirir un plan deportivo? <br /> ¡No esperes más!"</p>

                                <div className='project__icon'>
                                    <a target="_blank" href="https://luisguerrerodev.com/hardwork/"><i className='bx bx-play-circle'></i><span>Demo</span></a>
                                    <a target="_blank" href="https://github.com/LMGP2002/ProyectoWeb"><i className='bx bxl-github'></i></a>
                                </div>


                            </div>
                        </div>
                        <div className="box">
                            <img alt="" src={P2} />
                            <div className="overlay">
                                <div className='technologies__container'>
                                    <p className='project__technologies'><i className='bx bxl-html5'></i><span>HTML</span></p>
                                    <p className='project__technologies'><i className='bx bxl-css3' ></i><span>CSS</span></p>
                                    <p className='project__technologies'><i className='bx bxs-file-js'></i><span>JS</span></p>
                                </div>
                                <h3>VitalSport</h3>
                                <p>Sitio web responsive con principios de usabilidad y accesibilidad web para que su navegación sea más inclusiva.</p>
                                <div className='project__icon'>
                                    <a target="_blank" href="https://luisguerrerodev.com/vitalsport/"><i className='bx bx-play-circle'></i><span>Demo</span></a>
                                    <a target="_blank" href="https://github.com/LMGP2002/ProyectoUsabilidad"><i className='bx bxl-github'></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <img alt="" src={P3} />
                            <div className="overlay">
                                <div className='technologies__container'>
                                    <p className='project__technologies'><i className='bx bxl-html5'></i><span>HTML</span></p>
                                    <p className='project__technologies'><i className='bx bxl-css3' ></i><span>CSS</span></p>
                                    <p className='project__technologies'><i className='bx bxl-php' ></i></p>
                                    <p className='project__technologies'><i className='bx bxs-data'></i><span>MySQL</span></p>
                                    <p className='project__technologies'><i className='bx bxs-file-js'></i><span>JS</span></p>
                                </div>
                                <h3>Inventario</h3>
                                <p>Sistema de inventario diseñado para la empresa Café Arte Villa Monguí, ubicada en Duitama, Boyacá.</p>
                                <div className='project__icon'>
                                    <a target='_blank' href="https://github.com/LMGP2002/ProyectoSistemaInventario"><i className='bx bxl-github'></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <img alt="" src={P4} />
                            <div className="overlay">
                                <div className='technologies__container'>
                                    <p className='project__technologies'><i className='bx bxl-angular'></i><span>Angular</span></p>
                                    <p className='project__technologies'><i className='bx bxl-spring-boot' ></i><span>Spring Boot</span></p>
                                    <p className='project__technologies'><i className='bx bxl-mongodb' ></i>MongoDB</p>
                                    <p className='project__technologies'><i className='bx bxs-data'></i><span>MySQL</span></p>
                                </div>
                                <h3>SportStore</h3>
                                <p>Carrito de compras distribuido con arquitectura en microservicios.</p>
                                <div className='project__icon'>
                                    <a target='_blank' href="https://github.com/LMGP2002/ShoppingCart"><i className='bx bxl-github'></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="box">
                            <img alt="" src={P5} />
                            <div className="overlay">
                                <div className='technologies__container'>
                                    <p className='project__technologies'><i class='bx bxl-react'></i><span>React</span></p>
                                    <p className='project__technologies'><i className='bx bxl-html5'></i><span>HTML</span></p>
                                    <p className='project__technologies'><i className='bx bxl-css3' ></i><span>CSS</span></p>
                                    <p className='project__technologies'><i className='bx bxs-file-js'></i><span>JS</span></p>
                                </div>
                                <h3>Portafolio</h3>
                                <p>Conoce el código de mi portafolio y la estructura que se ha llevado a cabo para construirlo.</p>
                                <div className='project__icon'>
                                    <a target='_blank' href="https://github.com/LMGP2002/Portafolio"><i className='bx bxl-github'></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Portfolio