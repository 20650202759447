import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Social = () => {
  const copyToClipboard = (text, msg) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success(msg, {
        autoClose: 2000
      });
    }).catch(err => {
      console.error('Error al copiar el texto: ', err);
    });
  };

  return (
    <div className="home__social">
      <a href="https://www.linkedin.com/in/luismiguelgp2002/" className="home__social-icon" target="_blank">
        <i className='uil uil-linkedin'></i>
      </a>

      <a onClick={(e) => { e.preventDefault(); copyToClipboard('guerreromiguel.2002@gmail.com', 'Correo copiado al portapapeles'); }}  href="#" className="home__social-icon" target="_blank">
        <i className='uil uil-envelope'></i>
      </a>


    </div>
  )
}

export default Social