import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
            <i className='bx bx-award about__icon'></i>
            <h3 className='about__title'>Proyectos</h3>
            <span className="about__subtitle">+4 proyectos</span>
        </div>

        <div className="about__box">
            <i className='bx bx-briefcase-alt about__icon'></i>
            <h3 className='about__title'>Formación</h3>
            <span className="about__subtitle">Ing. Sistemas</span>
        </div>
    </div>
  )
}

export default Info