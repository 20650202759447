import React from 'react'
import './footer.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const copyToClipboard = (text, msg) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success(msg, {
                autoClose: 2000
            });
        }).catch(err => {
            console.error('Error al copiar el texto: ', err);
        });
    };
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">luisguerrero<span className='footer__logo-extension'>.dev</span></h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">Sobre mí</a>
                    </li>

                    <li>
                        <a href="#portfolio" className="footer__link">Proyectos</a>
                    </li>

                    <li>
                        <a href="#experience" className="footer__link">Experiencia</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.linkedin.com/in/luismiguelgp2002/" className="footer__social-link" target="_blank">
                        <i className='bx bxl-linkedin'></i>
                    </a>

                    <a href="#" onClick={(e) => { e.preventDefault(); copyToClipboard('guerreromiguel.2002@gmail.com', 'Correo copiado al portapapeles'); }} className="footer__social-link" target="_blank">
                        <i className='bx bxl-gmail'></i>
                    </a>
                </div>

                <span className="footer__copy">
                    &#169; 2024 Luis Dev. All rights reserved. | Designed and developed by Luis.
                </span>
            </div>
        </footer>
    )
}

export default Footer