import React from 'react';
import './contact.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const copyToClipboard = (text, msg) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success(msg, {
                autoClose: 2000
            });
        }).catch(err => {
            console.error('Error al copiar el texto: ', err);
        });
    };

    return (
        <section className='contact section' id="contact">
            <h2 className="section__title">Contáctame</h2>
            <span className='section__subtitle'>A través de...</span>

            <div className="contact__container">
                <div className="contact-info__container">
                    <a href="#" onClick={(e) => { e.preventDefault(); copyToClipboard('guerreromiguel.2002@gmail.com', 'Correo copiado al portapapeles'); }}>
                        <i className='bx bxs-envelope icon'></i><span>guerreromiguel.2002@gmail.com</span>
                    </a>
                </div>
                <div className="contact-info__container">
                    <a target='_blank' href="https://www.linkedin.com/in/luismiguelgp2002/"><i className='bx bxl-linkedin icon'></i><span>Linkedin</span></a>
                </div>
                <div className="contact-info__container">
                    <a href="#" onClick={(e) => { e.preventDefault(); copyToClipboard('3127204406', 'Teléfono copiado al portapapeles'); }}><i className='bx bxl-whatsapp icon' ></i><span>+57 312 7204406</span></a>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
};

export default Contact;
